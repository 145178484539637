import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App.js';

//below imports for dapp minting
import store from "./redux/store.js";
import { Provider } from "react-redux";
//import MintApp from './zombiemint.js';

const container = document.getElementById('root');
const root = createRoot(container); 
root.render(
    <Provider store={store}>
        <App tab="home" />
        </Provider>,);

// dapp to mint NFTS below
/*const domContainer = document.querySelector('#zombiemint_container');
const mintroot = createRoot(domContainer);
mintroot.render(MintApp);  */