import React, { useEffect, useState, useRef, Component } from "react";
import { BrowserRouter, Route } from "react-router-dom";

import Navbar from "./components/Navbar/Navbar.js"
import UAuth from '@uauth/js';

/* Add Blockchain Code to Mint NFTs as dApp */
import { useDispatch, useSelector } from "react-redux";
/*
import { connect } from "./redux/blockchain/blockchainActions.js";
import { fetchData } from "./redux/data/dataActions.js";
const truncate = (input, len) =>
  input.length > len ? `${input.substring(0, len)}...` : input;
/* end added blockchain stuff */

var uauth = "";
/* PRODUCTION */
if (process.env.NODE_ENV === "production") {
  console.log("production uauth");
  uauth = new UAuth({
    clientID: "975e52f7-648f-4d6e-b256-85179528616d",
    redirectUri: "https://nonfungame.xyz/",
    scope: "openid wallet email:optional profile:optional social:optional badges:optional messaging:notifications:optional"
  }); 
} else {
  console.log("local uauth");
  /* LOCAL TEST */
  uauth = new UAuth({
    clientID: "975e52f7-648f-4d6e-b256-85179528616d",
    redirectUri: "http://localhost:3000/",
    scope: "openid wallet email:optional profile:optional social:optional humanity_check:optional badges:optional messaging:notifications:optional"
  }) 
}

function search_array(array,valuetofind) {
  for (var i = 0; i < array.length; i++) {
      if (array[i]['code'] === valuetofind) {
          return array[i];
      }
  }
  return -1;
}

window.getDomain = async (qparam) => {
  try {
    console.log("getDomain called with qparam: " + qparam);
      const authorization = await uauth.loginWithPopup({"flowId": "signup", "signupSuggestion": qparam});
      localStorage.setItem("user", authorization.idToken);
      window.loginSuccess(authorization.idToken);
    } catch (error) {
      console.error(error)
    }
}

window.login = async () => {
  try {
      const sessionuser = localStorage.getItem("user");
      if (sessionuser !== undefined){
          const authorization = await uauth.loginWithPopup()
          localStorage.setItem("user", authorization.idToken);

          if (authorization.idToken.twitter) {
            //console.log("we have twitter", authorization.idToken.twitter);
            const payload = {
              method: 'POST', 
              headers:{
                "Accept":"application/json",
                'Content-Type': 'application/json'
            },
              mode: 'cors', 
              body: JSON.stringify(authorization.idToken.twitter)
            };
            
            fetch('/api/tweets', payload)
              .then(res => {
                  console.log("Tweeted to this user");
                  console.log(res);
                  return res.json()
               });
          }
          
          window.loginSuccess(authorization.idToken);

          //check if nonfungame
          var badges = authorization.idToken.badges;
          if (badges){
            //user has some badges
            var nonfun = "non_fun_game";
            var moonalisa = "opensea-tothemoonalisa";
            var discountcode = "";
            var nonfunbadges = [];
            var count = 0;

            var nonfunbadge = search_array(badges,nonfun);
            var moonabadge = search_array(badges,moonalisa);

            if (nonfunbadge != -1){
              console.log("has a non fun badge");
              //discountcode += "nonfun";
              discountcode = "NFTHODLER";
              nonfunbadges.push(nonfunbadge);
            }

            if (moonabadge != -1){
              console.log("has a moonalisa badge");
              //discountcode += "moona";
              discountcode = "NFTHODLER";
              nonfunbadges.push(moonabadge);
            }

            window.nonfunhodler(discountcode, nonfunbadges);
           
          }
      } else {
          window.loginSuccess(sessionuser);
      }
    
      } catch (error) {
        console.error(error)
      }
}


class App extends Component { //before blockchain logic
/*function App()  {
  /* Blockchain code *
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [claimingNft, setClaimingNft] = useState(false);
  const [feedback, setFeedback] = useState(`Click buy to mint your NFT.`);
  const [mintAmount, setMintAmount] = useState(1);
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
  });
  
  const claimNFTs = () => {
    let cost = CONFIG.WEI_COST;
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei = String(cost * mintAmount);
    let totalGasLimit = String(gasLimit * mintAmount);
    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .mint(mintAmount)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          `WOW, the ${CONFIG.NFT_NAME} is yours! go visit Opensea.io to view it.`
        );
        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
      });
  };

const decrementMintAmount = () => {
  let newMintAmount = mintAmount - 1;
  if (newMintAmount < 1) {
    newMintAmount = 1;
  }
  setMintAmount(newMintAmount);
};

const incrementMintAmount = () => {
  let newMintAmount = mintAmount + 1;
  if (newMintAmount > 10) {
    newMintAmount = 10;
  }
  setMintAmount(newMintAmount);
};

const getData = () => {
  if (blockchain.account !== "" && blockchain.smartContract !== null) {
    dispatch(fetchData(blockchain.account));
  }
};

const getConfig = async () => {
  const configResponse = await fetch("/config/config.json", {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
  const config = await configResponse.json();
  SET_CONFIG(config);
};

useEffect(() => {
  getConfig();
}, []);

useEffect(() => {
  getData();
}, [blockchain.account]);

/* end blockchain code */

  render() {
  return (
    <div className="App">
      <BrowserRouter>
       <Navbar />
      </BrowserRouter>
    </div>
  );
  }
}


export default App;
