import React, { useState , Component } from "react";


class Navbar extends Component {
	render() {
        return(
            <div>
	<div className="metaportal_fn_mobnav">
		<div className="mob_top">
			<div className="social_trigger">
				<div className="trigger">
					<span></span>
				</div>
				<div className="social">
					<ul>
						<li><a href="https://dexscreener.com/solana/hjh57e2xuywjnkeukxmwenjudmjhovgyaht3shkeiyk9" target="_blank" rel="noreferrer"><i className="fa-solid fa-brain"></i></a></li>
						<li><a href="https://www.twitter.com/zomzeb" target="_blank" rel="noreferrer"><i className="fa-brands fa-twitter"></i></a></li>
					</ul>
				</div>
			</div>
			<div className="wallet">
			<a href="#" className="metaportal_fn_unstoppable">
						<img src="svg/userbadge.svg" alt="" className="fn__svg unstoppable bounce" />
					</a>
				<button className="metaportal_fn_button wallet_opener"><span>Connect</span></button>
                <ul className="loggedin" role="menu" tabIndex="0" data-menu-list="true">
                    <li className="" role="menuitem" tabIndex="-1" data-menu-id="rc-menu-uuid-23559-1-0"><span className="ant-dropdown-menu-title-content">
                        <div className="copywallet"><span id="p1"></span><span className="p2">Profile (0xCrypto)</span>
                        <i className="fa-regular fa-copy"></i>
                        </div></span></li>
                    <li className="" role="menuitem" tabIndex="-1" data-menu-id="rc-menu-uuid-23559-1-1"><span className="ant-dropdown-menu-title-content">
						<button className="creative_link disconnect"> Disconnect</button></span></li>
                </ul>
            </div>
		</div>
		<div className="mob_mid">
			<div className="logo">
				<a href="index.html"><img src="img/logo.png" alt=""/></a>
			</div>
			<div className="trigger">
				<span></span>
			</div>
		</div>
		<div className="mob_bot">
			<ul>
				<li><a className="creative_link" href="#home">Home</a></li>
				<li><a className="creative_link" href="#about">About</a></li>
				<li><a className="creative_link" href="#collection">Memecoin</a></li>
				<li><a className="creative_link" href="#dex">Dex</a></li>
				{/* <li><a className="creative_link stripeurl" href="https://buy.stripe.com/28o7tFgX75oz2ZO7ss" target="_blank" rel="noreferrer">Pre-Order</a></li>
				 */}
			</ul>
		</div>
	</div>

            <header id="header">
		<div className="header">
			<div className="header_in">
			
				<div className="trigger_logo">
					<div className="trigger">
						<span></span>
					</div>
					<div className="logo">
						<a href="index.html"><img src="img/logo.png" alt="" /></a>
					</div>
				</div>
				
				<div className="nav">
					<ul>
						<li><a className="creative_link" href="#home">Home</a></li>
						<li><a className="creative_link" href="#about">About</a></li>
						<li><a className="creative_link" href="#collection">Memecoin</a></li>
						<li><a className="creative_link" href="#dex">Dex</a></li>
						{/* <li><a className="creative_link stripeurl" href="https://buy.stripe.com/28o7tFgX75oz2ZO7ss" target="_blank" rel="noreferrer">Pre-Order</a></li> */}
					</ul>
				</div>
				
				<div className="wallet">
					<a href="#" className="metaportal_fn_unstoppable2">
						<img src="svg/userbadge.svg" alt="" className="fn__svg unstoppable bounce" />
					</a>
					<button className="metaportal_fn_button wallet_opener"><span>Connect Wallet</span></button>
                    <ul className="loggedin" role="menu" tabIndex="0" data-menu-list="true">
                        <li className="" role="menuitem" tabIndex="-1" data-menu-id="rc-menu-uuid-23559-1-0"><span className="ant-dropdown-menu-title-content">
                            <div className="copywallet"><span id="p1"></span><span className="p2">Profile (0xCrypto)</span>
                            <i className="fa-regular fa-copy"></i>
                            </div></span></li>
                        <li className="" role="menuitem" tabIndex="-1" data-menu-id="rc-menu-uuid-23559-1-1"><span className="ant-dropdown-menu-title-content">
							<button className="creative_link disconnect"> Disconnect</button></span></li></ul>
				</div>
				
			</div>
		</div>
	</header>
            </div>
        )
}
};

export default Navbar;